import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import tagReducer from './slices/tag';
import userReducer from './slices/user';
import formReducer from './slices/form';
import tenantReducer from './slices/tenant';
import moduleReducer from './slices/module';
import folderReducer from './slices/folder';
import companyReducer from './slices/company';
import documentReducer from './slices/document';
import fileViewReducer from './slices/fileView';
import templateReducer from './slices/template';
import dlxDriveReducer from './slices/dlxDrive';
import calendarReducer from './slices/calendar';
import jobTitlesReducer from './slices/jobTitles';
import controlCodeReducer from './slices/controlCode';
import formBuilderReducer from './slices/formBuilder';
import addressBookReducer from './slices/addressBook';
import departmentsReducer from './slices/departments';
import accessGroupReducer from './slices/accessGroup';
import documentSignerReducer from './slices/documentSigner';
import documentHistoryReducer from './slices/documentHistory';
import rolePermissionsReducer from './slices/rolePermissions';
import signatureSessionReducer from './slices/signatureSession';
import signaturePositionReducer from './slices/signaturePosition';
import sessionControlCodeReducer from './slices/sessionControlCode';
import systemNotificationsReducer from './slices/systemNotifications';
import signaturePadReqModelReducer from './slices/signaturePadReqModel';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const systemNotificationsPersistConfig = {
  key: 'systemNotifications',
  storage,
  keyPrefix: 'redux-',
};

const sessionControlCodePersistConfig = {
  key: 'session-control-code',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sessionId'],
};

const rootReducer = combineReducers({
  document: documentReducer,
  documentHistory: documentHistoryReducer,
  documentSigner: documentSignerReducer,
  dlxDrive: dlxDriveReducer,
  accessGroup: accessGroupReducer,
  controlCode: controlCodeReducer,
  signatureSession: signatureSessionReducer,
  signaturePadReqModel: signaturePadReqModelReducer,
  signaturePosition: signaturePositionReducer,
  user: userReducer,
  rolePermissions: rolePermissionsReducer,
  company: companyReducer,
  fileView: fileViewReducer,
  folder: folderReducer,
  template: templateReducer,
  tag: tagReducer,
  tenant: tenantReducer,
  module: moduleReducer,
  form: formReducer,
  formBuilder: formBuilderReducer,
  calendar: calendarReducer,
  addressBook: addressBookReducer,
  departments: departmentsReducer,
  jobTitles: jobTitlesReducer,
  systemNotifications: persistReducer(systemNotificationsPersistConfig, systemNotificationsReducer),
  sessionControlCode: persistReducer(sessionControlCodePersistConfig, sessionControlCodeReducer),
});

export { rootReducer, rootPersistConfig };
